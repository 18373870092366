<template>
    <div class="row">
        <div class="col-sm-4 col-md-3">
            <div class="formblock">
                <p class="mb-2">Range slider two way</p>
                <input class="w-100" type="range" v-model="rangeSlider">
                <input class="w-100" type="text" v-model="rangeSlider">
                <p v-html="rangeSliderText"></p>
            </div>
            <div class="formblock">
                <p class="mb-2">Checkboxes</p>
                <div v-for="(value,key) in checkboxes" :key="key" class="checkboxes">
                    <input class="mr-3" type="checkbox" v-model="checkboxChecked" :value="value" :id="key">
                    <label :for="key" :class="{ '__active' : checkboxChecked.includes(value) }">{{ value }}</label>
                </div>
                <div v-html="checkboxValsArr"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: "Forms Block",
	data() {
		return {
            rangeSlider: 1,
            checkboxes: [
                'Digestive','Hobnob','Jammie dodger','Rich tea','Party rings'
            ],
            checkboxChecked: [],
		}
	},
    computed: {
        rangeSliderText() {
            return `<p>Range slider value: ${this.rangeSlider}</p>`;
        },
        checkboxValsArr() {
            return `<p>Checkboxes checked: <br> ${this.checkboxChecked}</p>`;
        }
    },
	methods: {
	},
};
</script>

<style lang="scss" scoped>
.formblock {
    margin: 0 0 40px 0;
}
.checkboxes {
    input {
        position: absolute;
        left: -99999px;
    }
    label {
        position: relative;
        padding: 0 0 0 30px;
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 15px;
            width: 20px;
            height: 20px;
            transform: translate(-50%,-50%);
            z-index: 1;
            background: #d0d0d0;
            border-radius: 2px;
        }
        &::after {
            width: 10px;
            height: 10px;
            z-index: 2;
            background: #efefef;
            opacity: 0;
            transition: all 0.3s ease;
        }
        &.__active {
            &::after {
                opacity: 1;
            }
        }
    }
}
</style>
