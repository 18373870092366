import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';

// css
import './scss/main.scss'

// plugins
import "bootstrap/dist/css/bootstrap.min.css";
import 'simplebar';
import 'simplebar/dist/simplebar.css';

// app files
import App from './App.vue'
import ButtonsPage from './buttonpage/ButtonsPage.vue';
import ComponentLibrary from './componentlibrary/ComponentLibrary.vue';
import DemoPage from './demopage/DemoPage.vue';

// routing
const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    // base: '/current/',
    routes: [
        { path: '/buttons', component: ButtonsPage, name: 'Buttons' },
        { path: '/components', component: ComponentLibrary, name: 'Components' },
        { path: '/demopage', component: DemoPage, name: 'Demopage' },
    ],
    linkActiveClass: '__active'
});


// create app
const app = createApp(App);

app.use(router);

app.mount('#app')
