<template>
    <section class="wrapper">
		<top-nav></top-nav>
		<router-view></router-view>
	</section>
</template>

<script>
import TopNav from "./root-components/TopNav.vue";

export default {
    name: "App",
    components: {
        TopNav
    },
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
};
</script>

<style lang="scss">
.wrapper {
    height: 100vh;
    overflow: hidden;
}
</style>
