<template>
    <div class="tabsbar">
        <button 
            @click="activeTab = 1" 
            :class="{ '__active' : activeTab == 1 }"
            role="tab"
            id="tab1"
            :aria-selected="activeTab == 1"
            aria-controls="tabpanel1">
            Tab 1
        </button>
        <button 
            @click="activeTab = 2" 
            :class="{ '__active' : activeTab == 2 }"
            role="tab"
            id="tab2"
            :aria-selected="activeTab == 2"
            aria-controls="tabpanel2">
            Tab 2
        </button>
        <button 
            @click="activeTab = 3" 
            :class="{ '__active' : activeTab == 3 }"
            role="tab"
            id="tab3"
            :aria-selected="activeTab == 3"
            aria-controls="tabpanel3">
            Tab 3
        </button>
    </div>
    <div class="tabpanels">
        <div 
        class="tabpanel" 
        v-if="activeTab == 1" 
        id="tabpanel1" 
        aria-labelledby="tab1"
        :aria-expanded="activeTab == 1">
            <div class="container py-5">
                <forms-block></forms-block>
            </div>
        </div>
    </div>
</template>

<script>

import FormsBlock from "./components/Forms.vue";

export default {
	name: "Demo page",
	components: {
		FormsBlock,
	},
	data() {
		return {
            activeTab: 1,
		}
	},
	methods: {
	},
};
</script>

<style lang="scss" scoped>
.tabsbar {
    display: flex;
    button {
        width: calc(100%/3);
        padding: 15px 0;
        transition: all 0.3s ease;
        &.__active {
            background: #d0d0d0;
        }
        &:hover,
        &:focus {
            background: #d0d0d0;
        }
    }
}
</style>
